import { apiSlice } from "./apiSlice"
export const branchApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBranches: builder.mutation({
            query: () => ({
                url: '/branches',
                method: 'GET',
            }),
        }),
        deactivateBranch: builder.mutation({
            query: ({id}) => ({
                url: `/branches/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateBranch: builder.mutation({
            query: ({id}) => ({
                url: `/branches/activate/${id}`,
                method: 'PUT',
            }),
        }),
        closeBranch: builder.mutation({
            query: ({id}) => ({
                url: `/branches/close/${id}`,
                method: 'PUT',
            }),
        }),
        openBranch: builder.mutation({
            query: ({id}) => ({
                url: `/branches/open/${id}`,
                method: 'PUT',
            }),
        }),
        addBranch: builder.mutation({
            query: formData => ({
                url: '/branches',
                method: 'POST',
                body:formData
            }),
        }),
      
})
})
export const {
useActivateBranchMutation,
useCloseBranchMutation,
useDeactivateBranchMutation,
useGetBranchesMutation,
useOpenBranchMutation,
useAddBranchMutation
} = branchApiSlice 