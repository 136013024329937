import React, { useState } from 'react';
import './login.scss';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/authReducer';
import { useLoginMutation } from '../../api/authApiSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Login() {
  const notify = (error) => toast(error);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()
  const [login] = useLoginMutation()
  const handleLogin = async (e) => {
    e.preventDefault()
    try {
        const { accessToken } = await login({ username, password }).unwrap()
        dispatch(loginSuccess({user: accessToken }))
        setUsername('')
        setPassword('')
    } catch (err) {
        if (!err.status) {
          notify('No Server Response');
        } else if (err.status === 400) {
          notify('Missing Username or Password');
        } else if (err.status === 401) {
          notify('Unauthorized');
        } else {
          notify(err.data?.message);
        }
    }
  }
//   const handleLogin = (e) => {
//     e.preventDefault();
//     if (username && password) {
//       axios.post(process.env.REACT_APP_API_URL+'auth', {
//         username: username,
//         password: password
//       },{headers:{
//         "Authorization":`Bearer ${process.env.REACT_APP_API_TOKEN}`
//       }})
//         .then(response => {
//           if (response.data.message === "success") {
//             dispatchUser({ type: "LOGIN_SUCCESS", payload:JSON.stringify(response.data.data)});
//           } else {
//             alert(response.data.message);
//           }
//         })
//         .catch(error => {
      
//           toast("An error occurred during login.");
         

//         });
//     } else {
//       toast("Please enter username and password");
  

//     }
// }
  return (
    <div className="login">
      <h3>ElbeOrder Login</h3>
      <form onSubmit={handleLogin} className="form">
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit" className="button" >Login</button>
        <ToastContainer />
      </form>
    </div>
  );
}

export default Login;