import { apiSlice } from "./apiSlice"
export const authoritiyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAuthorities: builder.mutation({
            query: () => ({
                url: '/authorities',
                method: 'GET',
            }),
        }),
        getUserAuthorities: builder.mutation({
            query: () => ({
                url: '/userauthorities',
                method: 'GET',
            }),
        }),
        deactivateUserAuthority: builder.mutation({
            query: ({id}) => ({
                url: `/userauthorities/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateUserAuthority: builder.mutation({
            query: ({id}) => ({
                url: `/userauthorities/activate/${id}`,
                method: 'PUT',
            }),
        }),
    })
})

export const {
    useGetAuthoritiesMutation,
    useGetUserAuthoritiesMutation,
    useActivateUserAuthorityMutation,
    useDeactivateUserAuthorityMutation
} = authoritiyApiSlice 