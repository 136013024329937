import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddBranchMutation } from "../../api/branchApiSlice";


const NewBranches = () => {
  const [error,setError] = useState(false)
  const [branches,setBranches] = useState()
  const [addBranch] = useAddBranchMutation()
  const navigate = useNavigate();



  const handleChange = (e) => {
    setBranches((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

  const handleClick = async (e) => {
    e.preventDefault();


        try {
          await addBranch({name:branches.name,regionName:branches.regionName})
          navigate("/branches");
        } catch (error) {
          setError(true)
        }

  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Branch</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
            <form>
              <div className="formInput">
                  <label>Branch Name(*)</label>
                  <input name="name" required onChange={handleChange} type="text" placeholder="Type Branch Name" />
              </div>
              <div className="formInput">
                  <label>Region Name(*)</label>
                  <input name="regionName" required onChange={handleChange} type="text" placeholder="Type Region Name" />
              </div>
            </form>
            <button onClick={handleClick}>Add</button>
              {error && "Something went wrong!"}

      </div>
      </div>
    </div>
  </div>


  );
};

export default NewBranches;
