import { apiSlice } from "./apiSlice"
export const categoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubcategories: builder.mutation({
            query: ({branchId}) => ({
                url: `/subcategories/branches/${branchId}`,
                method: 'GET',
            }),
        }),
        addSubcategory: builder.mutation({
            query: formData => ({
                url: '/subcategories',
                method: 'POST',
                body:formData
            }),
        }),
        deactivateSubcategory: builder.mutation({
            query: ({id}) => ({
                url: `/subcategories/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateSubcategory: builder.mutation({
            query: ({id}) => ({
                url: `/subcategories/activate/${id}`,
                method: 'PUT',
            }),
        }),
        deleteSubcategory: builder.mutation({
            query: ({id}) => ({
                url: `/subcategories/${id}`,
                method: 'DELETE',
            }),
        }),
     
})
})
export const {
useAddSubcategoryMutation,
useDeleteSubcategoryMutation,
useActivateSubcategoryMutation,
useDeactivateSubcategoryMutation,
useGetSubcategoriesMutation
} = categoryApiSlice 