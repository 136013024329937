import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { selectCurrentToken } from "../../redux/authReducer";
import { useActivateVariantGroupMutation, useDeactivateVariantGroupMutation, useDeleteVariantGroupMutation, useGetVariantGroupsMutation } from "../../api/variantgroupApiSlice";

const VariantGroups = () => {
  const [variantGroups,setVariantGroups] = useState([])
  const [getVariantGroups] = useGetVariantGroupsMutation()
  const [deactivate] = useDeactivateVariantGroupMutation()
  const [activate] = useActivateVariantGroupMutation()
  const [deleteVariantGroup] = useDeleteVariantGroupMutation()
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  useEffect(()=>{
    const fetchVariantGroups = async() =>{
      try {
        const res = await getVariantGroups({branchId})
        setVariantGroups(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchVariantGroups()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async(id) =>{
    try {
      await deleteVariantGroup({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 270,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
            <div
              className="deleteButton"
               onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

   const variantGroupsColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "variantGroupName",
      headerName: "Variant Group Name",
      width: 170,
    },
    {
      field: "regionName",
      headerName: "Region Name",
      width: 150,
    },
    { field: "isActive", headerName: "Active", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Variant Group
            <Link to="/variantgroups/new" className="link">
              Add New
            </Link>
          </div>
          <DataGrid
            className="datagrid"
            rows={variantGroups}
            columns={variantGroupsColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default VariantGroups;
