import { apiSlice } from "./apiSlice"
export const customercardApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCustomerCards: builder.mutation({
            query: () => ({
                url: '/customerscards',
                method: 'GET',
            }),
        }),
    })
})

export const {
    useGetCustomerCardsMutation
} = customercardApiSlice 