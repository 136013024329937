import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddVariantGroupMutation } from "../../api/variantgroupApiSlice";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
import { useEffect } from "react";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import Select from "react-select";

const NewVariantGroup = () => {
  const [error,setError] = useState(false)
  const [variantGroup,setVariantGroup] = useState()
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  const navigate = useNavigate();
  //branches-------------------------------------------------------------------
  const [getBranches] = useGetBranchesMutation()
  const [branches,setBranches] = useState([])
  const branchesList = []
  useEffect(()=>{
    const fetchBranches = async()=>{
      const res = await getBranches()
      setBranches(res.data)
    }
    fetchBranches()
  },[])
  
  branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
  const [branchesOption,setBranchesOption] = useState()
  const handleBranches = (data) =>{
    setBranchesOption(data)
  }
 
  //----------------------------------------------------------------------
  const handleChange = (e) => {
    setVariantGroup((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  const [addVariantGroup] = useAddVariantGroupMutation()
  const handleClick = async (e) => {
    e.preventDefault();

        try {
          await addVariantGroup({variantGroupName:variantGroup.variantGroupName,branchId:branchesOption.value})
          navigate("/variantgroups");
        } catch (error) {
          setError(true)
        }
  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Variant</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
            <form>
              <div className="formInput">
                  <label>Variant Group Name</label>
                  <input name="variantGroupName" onChange={handleChange} type="text" placeholder="Type Variant Group Name" />
              </div>
              {
              branchId === 0 | branchId === 1 &&
              <div className="formInput">
                <label>Branch(*)</label>
              <Select
                required
                options={branchesList}
                placeholder="Select branch"
                value={branchesOption}
                onChange={handleBranches}
                isSearchable={true}
                
              /></div>
                }
            
            </form>
            <button onClick={handleClick}>Add</button>
              {error && "Something went wrong!"}

      </div>
      </div>
    </div>
  </div>


  );
};

export default NewVariantGroup;
