import { apiSlice } from "./apiSlice"
export const branchescardApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBranchescards: builder.mutation({
            query: () => ({
                url: '/branchescards',
                method: 'GET',
            }),
        }),
        deactivateBranchescard: builder.mutation({
            query: ({id}) => ({
                url: `/branchescards/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateBranchescard: builder.mutation({
            query: ({id}) => ({
                url: `/branchescards/activate/${id}`,
                method: 'PUT',
            }),
        }),
        deleteBranchescard: builder.mutation({
            query: ({id}) => ({
                url: `/branchescards/${id}`,
                method: 'DELETE',
            }),
        }),
        addBranchescard: builder.mutation({
            query: formData => ({
                url: '/branchescards',
                method: 'POST',
                body:formData
            }),
        }),
     
})
})
export const {
useActivateBranchescardMutation,
useDeactivateBranchescardMutation,
useGetBranchescardsMutation,
useAddBranchescardMutation,
useDeleteBranchescardMutation
} = branchescardApiSlice 