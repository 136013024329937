import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useGetRolesMutation } from "../../api/roleApiSlice";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
import { useAddUserMutation } from "../../api/userApiSlice";
const NewUser = () => {
  const [error,setError] = useState(false)
  const [user,setUser] = useState()
  const navigate = useNavigate();
//roles------------------------------------------------
  const [getRoles] = useGetRolesMutation()
  const [roles,setRoles] = useState([])
  const rolesList = []
  useEffect(()=>{
    const fetchRoles = async()=>{
      const res = await getRoles()
      setRoles(res.data)
    }
    fetchRoles()
  },[])
  const [rolesOption, setRolesOption] = useState();
 
  roles.map(role=>rolesList.push({value:role.id,label:role.roleName}))

  const handleRoles = (data)=>  {
    setRolesOption(data);
   }
//----------------------------------------------------------------------------


//branches-------------------------------------------------------------------
const [getBranches] = useGetBranchesMutation()
const [branches,setBranches] = useState([])
const branchesList = []
useEffect(()=>{
  const fetchBranches = async()=>{
    const res = await getBranches()
    setBranches(res.data)
  }
  fetchBranches()
},[])

branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
const [branchesOption,setBranchesOption] = useState()
const handleBranches = (data) =>{
  setBranchesOption(data)
}



//----------------------------------------------------------------------
  const handleChange = (e) => {
      setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  const [addUser] = useAddUserMutation()
  const handleClick = async (e) => {
    e.preventDefault();
      
        try {
          await addUser({role:rolesOption.value,username:user.username,branchId:branchesOption.value,password:user.password})
          navigate("/users");
        } catch (error) {
          setError(true)
        }
  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New User</h5>
      </div>  
      <div className="addNewBottom">
      <div className="right">
        
            <form>
            <div className="formInput">
                <label>Role</label>
              <Select
                options={rolesList}
                placeholder="Select a role"
                value={rolesOption}
                onChange={handleRoles}
                isSearchable={true}
                
              /></div>
           
              <div className="formInput">
                  <label>Username</label>
                  <input name="username" onChange={handleChange} type="text" placeholder="Type Username" />
              </div>
              <div className="formInput">
                <label>Branch Name</label>
              <Select
                options={branchesList}
                placeholder="Select a role"
                value={branchesOption}
                onChange={handleBranches}
                isSearchable={true}
                
              /></div>
              <div className="formInput">
                  <label>Password</label>
                  <input name="password" onChange={handleChange} type="password" placeholder="Type Password" />
              </div>
            </form>
            <button onClick={handleClick}>Add</button>
              {error && "Something went wrong!"}

      </div>
      </div>
    </div>
  </div>


  );
};

export default NewUser;
