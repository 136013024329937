import { apiSlice } from "./apiSlice"
export const categoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.mutation({
            query: ({branchId}) => ({
                url: `/categories/${branchId}`,
                method: 'GET',
            }),
        }),
        addCategory: builder.mutation({
            query: formData => ({
                url: '/categories',
                method: 'POST',
                body:formData
            }),
        }),
        deactivateCategory: builder.mutation({
            query: ({id}) => ({
                url: `/categories/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateCategory: builder.mutation({
            query: ({id}) => ({
                url: `/categories/activate/${id}`,
                method: 'PUT',
            }),
        }),
        deleteCategory: builder.mutation({
            query: ({id}) => ({
                url: `/categories/${id}`,
                method: 'DELETE',
            }),
        }),
     
})
})
export const {
useActivateCategoryMutation,
useDeleteCategoryMutation,
useDeactivateCategoryMutation,
useAddCategoryMutation,
useGetCategoriesMutation,
} = categoryApiSlice 