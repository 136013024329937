import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import {Link} from "react-router-dom"
import { selectCurrentToken } from "../../redux/authReducer";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useActivatePostCodeMutation, useDeactivatePostCodeMutation, useDeletePostCodeMutation, useGetPostCodesMutation } from "../../api/postcodeApiSlice";

const PostCodes = () => {
  const [postCodes,setPostCodes] = useState([])
  const [getPostCodes] = useGetPostCodesMutation()
  const [deactivate] = useDeactivatePostCodeMutation()
  const [activate] = useActivatePostCodeMutation()
  const [deletePostCode] = useDeletePostCodeMutation()
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  useEffect(()=>{
    const fetchPostCodes = async() =>{
      try {
        const res = await getPostCodes({branchId})
        setPostCodes(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPostCodes()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  const handleDelete = async(id) =>{
    try {
      await deletePostCode({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 270,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="viewButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="completeButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
            <div
              className="deleteButton"
               onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

   const postCodesColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "postCode",
      headerName: "Post Code",
      width: 100,
    },
    {
      field: "regionName",
      headerName: "Primary Branch Name",
      width: 180,
    },
    {
      field: "sRegionName",
      headerName: "Secondary Branch Name",
      width: 180,
    },
    {
      field: "minOrder",
      headerName: "Minimum Amount",
      width: 150,
    },
    {
      field: "deliveryFee",
      headerName: "Delivery Fee",
      width: 150,
    },
    { field: "isActive", headerName: "Active", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Post Code
             <Link to="/postcodes/new" className="link">
              Add New
            </Link> 
          </div>
          <DataGrid
            className="datagrid"
            rows={postCodes}
            columns={postCodesColumns.concat(actionColumn)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default PostCodes;

 
 

