import { apiSlice } from "./apiSlice"
export const variantApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVariants: builder.mutation({
            query: ({branchId}) => ({
                url: `/variants/${branchId}`,
                method: 'GET',
            }),
        }),
        addVariant: builder.mutation({
            query: formData => ({
                url: '/variants',
                method: 'POST',
                body:formData
            }),
        }),
        deactivateVariant: builder.mutation({
            query: ({id}) => ({
                url: `/variants/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateVariant: builder.mutation({
            query: ({id}) => ({
                url: `/variants/activate/${id}`,
                method: 'PUT',
            }),
        }),
        deleteVariant: builder.mutation({
            query: ({id}) => ({
                url: `/variants/${id}`,
                method: 'DELETE',
            }),
        }),
     
})
})
export const {
useActivateVariantMutation,
useDeactivateVariantMutation,
useGetVariantsMutation,
useAddVariantMutation,
useDeleteVariantMutation
} = variantApiSlice 