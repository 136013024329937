import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Widget from "../../components/widget/Widget";
import "./home.scss";
import axios from "axios";
import Chart from "../../components/charts/Chart";
import Rechart from "../../components/charts/Rechart";
import Navbar from "../../components/navbar/Navbar";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import { useGetProductsListMutation } from "../../api/productApiSlice";
import { useGetOrdersMutation, useGetWaitingOrdersMutation } from "../../api/orderApiSlice";
const Home = () => {
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null  
  const [orders,setOrders] = useState([])
  const [chartOrders,setChartOrders] = useState([])
  const [products,setProducts] = useState([])
  const [getProducts] = useGetProductsListMutation()
  const [getOrders] = useGetOrdersMutation()
  const [getWaitingOrders] = useGetWaitingOrdersMutation()

  useEffect(()=>{
    const fetchOrders = async() =>{
      try {
        const res = await getWaitingOrders({branchId})
        setOrders(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchChartOrders = async() =>{
      try {
        const res = await getOrders({branchId})
        setChartOrders(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchProducts = async() =>{
      try {
        const res = await getProducts({branchId})
        setProducts(res.data)
      } catch (error) {
        console.log(error)
      }
    }
 
    fetchOrders()
    fetchChartOrders()
    fetchProducts()
  },[])
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <div className="widgets">
          <Widget type="product" amount={products.length}/>
          <Widget type="order" amount={orders.length}/>
        </div>
        {/* <div className="charts">
          <div className="chart">
            <span>Orders</span>
            <Chart/>
          </div>
          <div className="chart">
            <span>Orders</span>
            <Chart/>
          </div>
        </div> */}
       
      </div>
    </div>
  );
};

export default Home;
