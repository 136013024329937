import "./widget.scss";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import StoreIcon from "@mui/icons-material/Store";
import { Link } from "react-router-dom";

const Widget = ({ type, amount }) => {
  let data = null;
  switch (type) {
   
    case "order":
      data = {
        title: "ORDERS",
        isMoney: false,
        link: "View all orders",
        goTo: "/orders",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "product":
      data = {
        title: "PRODUCTS",
        isMoney: false,
        link: "View all products",
        goTo: "/products",
        icon: (
          <StoreIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    default:
      break;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{amount}</span>
        <Link to={data.goTo} style={{ textDecoration: "none", color: "grey" }}>
          <span className="link">{data.link}</span>
        </Link>
      </div>
      <div className="right">{data.icon}</div>
    </div>
  );
};

export default Widget;
