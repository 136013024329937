import { apiSlice } from "./apiSlice"
export const customerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCustomers: builder.mutation({
            query: () => ({
                url: '/customers',
                method: 'GET',
            }),
        }),
        deactivateCustomer: builder.mutation({
            query: ({id}) => ({
                url: `/customers/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateCustomer: builder.mutation({
            query: ({id}) => ({
                url: `/customers/activate/${id}`,
                method: 'PUT',
            }),
        }),
        secureCustomer: builder.mutation({
            query: ({id}) => ({
                url: `/customers/secure/${id}`,
                method: 'PUT',
            }),
        }),
        unsecureCustomer: builder.mutation({
            query: ({id}) => ({
                url: `/customers/unsecure/${id}`,
                method: 'PUT',
            }),
        }),
     
})
})
export const {
useActivateCustomerMutation,
useDeactivateCustomerMutation,
useGetCustomersMutation,
useSecureCustomerMutation,
useUnsecureCustomerMutation
} = customerApiSlice