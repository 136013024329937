import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import jwtDecode from "jwt-decode";
import { selectCurrentToken } from "../../redux/authReducer";
import { useSelector } from "react-redux";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
import { useAddSubcategoryMutation } from "../../api/subcategoryApiSlice";

const NewSubcategory = () => {
  const [error,setError] = useState(false)
  const [subcategory,setSubcategory] = useState()
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null

  //branches-------------------------------------------------------------------
  const [getBranches] = useGetBranchesMutation()
  const [branches,setBranches] = useState([])
  const branchesList = []
  useEffect(()=>{
    const fetchBranches = async()=>{
      const res = await getBranches()
      setBranches(res.data)
    }
    fetchBranches()
  },[])
  
  branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
  const [branchesOption,setBranchesOption] = useState()

  const handleBranches = (data) =>{
    setBranchesOption(data)
  }
  //----------------------------------------------------------------------
  
  const handleChange = (e) => {
    setSubcategory((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  const [addSubcategory] = useAddSubcategoryMutation()
  const handleClick = async (e) => {
    e.preventDefault();
    
        try {
          await addSubcategory({subcategoryName:subcategory.subcategoryName,branchId:branchesOption !== undefined ? branchesOption.value : branchId })
          navigate("/subcategories");
        } catch (error) {
          setError(true)
        }
  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Subcategory</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
            <form>
              <div className="formInput">
                  <label>Subcategory Name</label>
                  <input name="subcategoryName" onChange={handleChange} type="text" placeholder="Type Subcategory" />
              </div>
              {
              branchId === 0 &&
              <div className="formInput">
                <label>Branch Name</label>
              <Select
                options={branchesList}
                placeholder="Select a branch"
                value={branchesOption}
                onChange={handleBranches}
                isSearchable={true}
                
              /></div>
                }
             
            </form>
            <button onClick={handleClick}>Add</button>
              {error && "Something went wrong!"}

      </div>
      </div>
    </div>
  </div>


  );
};

export default NewSubcategory;
