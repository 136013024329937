import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import { useActivateCustomerMutation, useDeactivateCustomerMutation, useGetCustomersMutation, useSecureCustomerMutation, useUnsecureCustomerMutation } from "../../api/customerApiSlice";
const Customers = () => {
  const [customers,setCustomers] = useState([])
  const [getCustomers] = useGetCustomersMutation()
  const [deactivate] = useDeactivateCustomerMutation()
  const [activate] = useActivateCustomerMutation()
  const [secure] = useSecureCustomerMutation()
  const [unsecure] = useUnsecureCustomerMutation()

  useEffect(()=>{
    const fetchCustomers = async() =>{
      try {
        const res = await getCustomers()
        setCustomers(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCustomers()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  const handleSecure = async(id) =>{
    try {
      await secure({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  const handleUnsecure = async(id) =>{
    try {
      await unsecure({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }


  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
            <div
              className="viewButton"
               onClick={() => handleSecure(params.row.id)}
            >
              Secure
            </div>
            <div
              className="deleteButton"
               onClick={() => handleUnsecure(params.row.id)}
            >
              Unsecure
            </div>
          </div>
        );
      },
    },
  ];

   const customersColumns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
    },
    {
        field: "username",
        headerName: "Username",
        width: 150,
      },
      {
        field: "isSecure",
        headerName: "Secure User",
        width: 150,
      },
   
    { field: "isActive", headerName: "Active", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Customer
            {/* <Link to="/users/new" className="link">
              Add New
            </Link> */}
          </div>
          <DataGrid
            className="datagrid"
            rows={customers}
            columns={customersColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Customers;
