import "../../style/commonSingle.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect,useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Barcode from "react-barcode"
import { useCompleteOrderMutation, useGetSingleOrderMutation } from "../../api/orderApiSlice";


const SingleOrder = () => {
  const navigate = useNavigate()
  const [completeOrder] = useCompleteOrderMutation()
  const [orders,setOrders] = useState([])
  const [getSingleOrder] = useGetSingleOrderMutation()
  const location = useLocation()
  const handleComplete = async(id) =>{
    console.log(id)
    try {
      await completeOrder({id})
      navigate("/orders")
    } catch (error) {
      console.log(error)
    }
  }

  const calculate = (price)=>{
    let priceInKurus = price * 100;
    let priceInKurusString = priceInKurus.toFixed(0).padStart(5, '0');
    return priceInKurusString;
  }
  useEffect(()=>{

    const fetchOrder = async() =>{
      const id = location.pathname.split("/orders/")[1]
      try {
        const res = await getSingleOrder({id})
         console.log(res.data)
        setOrders(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOrder()
  },[location.pathname])

  const itemsByProductId = {}; 
  orders.forEach(item => {
    const productId = item.productId;
    if (!itemsByProductId[productId]) {
      itemsByProductId[productId] = []; 
    }
  
    // Check if the item already exists in the subcategory
    const existingItem = itemsByProductId[productId].find(i => i.productId === item.productId);
    if (existingItem) {
      // Add the variant to the existing item
      existingItem.variants.push({
        variantId : item.variantId,
        variantName: item.variantName,
        variantPrice: item.variantPrice,
        variantAmount: item.variantAmount
      
      });
    } else {
      // Create a new item with the variants array
      const newItem = {
        id: item.id,
        productId: item.productId,
        productName: item.productName,
        price: item.price,
        amount: item.amount,
        variants: [item.variantId !== null ? {
          variantId : item.variantId,
          variantName: item.variantName,
          variantPrice: item.variantPrice,
          variantAmount: item.variantAmount

        } : null]
      };
      itemsByProductId[productId].push(newItem);
    }
  });
  var totalPrice = 0
  Object.values(itemsByProductId).forEach(product => {
    product.forEach(row => {
      totalPrice += row.price * row.amount;

      // If the product has variants, add their prices to the total price
      if (row.variants.length > 0) {
        row.variants.forEach(variant => {
          totalPrice += variant.variantPrice * variant.variantAmount;
        });
      }
    });
  });
console.log(itemsByProductId)

  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
   <div className="singleContainer">
        {orders &&
            <div className="top" key={orders[0]?.id}>
            <div className="left">

              <div className="item">
            
                <div className="details">
                <span className="itemKey">ORDER ID:</span>
                  <h1 className="itemTitle">{orders[0]?.id}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Name and Surname:</span>
                    <span className="itemValue">{orders[0]?.name} {orders[0]?.surname}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Phone:</span>
                    <span className="itemValue">{orders[0]?.telNo}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address:</span>
                    <span className="itemValue">{orders[0]?.street + ","} {orders[0]?.postCode} {orders[0]?.city + "/"} {orders[0]?.country}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Floor:</span>
                    <span className="itemValue">{orders[0]?.floor}</span>
                  </div>
               
                  <div className="detailItem">
                    <span className="itemKey">Date:</span>
                    <span className="itemValue">{Date(orders[0]?.date)}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Requested Time:</span>
                    <span className="itemValue">{orders[0]?.requestedTime !== "-" ? Date(orders[0]?.requestedTime) : "-"}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Note:</span>
                    <span className="itemValue">{orders[0]?.note}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Completed:</span>
                    <span className="itemValue">{orders[0]?.isCompleted}</span>
                  </div>
                  <TableContainer component={Paper} className="table">
                  <table sx={{ minWidth: 650 }} aria-label="simple table">
                    <th>
                      <tr>
                        <td className="tableCell">Product Name</td>
                        <td className="tableCell">Amount</td>
                        <td className="tableCell">Price</td>
                        <td className="tableCell">Total</td>
                        <td className="tableCell">Barcode</td>
                      </tr>
                    </th>
                    <TableBody>
                    {Object.values(itemsByProductId).flatMap(product => product.map(row => (
      <div key={row.id} className="table">
        <tr >
          <td className="tableCell">{row?.productName}</td>
          <td className="tableCell">{row?.amount}</td>
          <td className="tableCell">{row?.price} €</td>
          <td className="tableCell">{(row?.price * row?.amount)} €</td>
          <td className="tableCell">
            <Barcode height={50} value={row.productId.toString() + calculate(row.price) + "1"} />
          </td>
        </tr>
           {row.variants.map(variant => (
          <tr key={variant.variantId} >
             <td className="tableCell">{variant?.variantName}</td>
          <td className="tableCell">{variant?.variantAmount}</td>
          <td className="tableCell">{variant?.variantPrice} €</td>
          <td className="tableCell">{(variant?.variantPrice * variant?.variantAmount)} €</td>
          <td className="tableCell">
            <Barcode height={50} value={variant.variantId.toString() + calculate(variant.variantPrice) + "1"} />
          </td>
          </tr>
        ))}
      
       
      </div>
    ))
  )}
</TableBody>

                    <TableBody key={orders[0]?.id}>
                    <TableRow >
                          <td className="tableCell"><b> Total Price </b></td>
                          <td className="tableCell"></td>
                          <td className="tableCell"></td>
                          <td className="tableCell"></td>
                          <td className="tableCell"></td>
                          <td className="tableCell"><b> {totalPrice} € </b></td>
                    </TableRow>
                    </TableBody>
                  </table>
                </TableContainer>

       
      </div>
    </div>
    <div className="completeButton" onClick={() => handleComplete(orders[0]?.id)}>Complete</div> 
  </div>
          
  </div>
}
</div>     
            

      </div> 
    </div>
  );
};

export default SingleOrder
