import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { selectCurrentToken } from "../../redux/authReducer";
import { useSelector } from "react-redux";
import { useActivateProductMutation, useDeactivateProductMutation, useGetProductsListMutation } from "../../api/productApiSlice";
const Products = () => {
  const [products,setProducts] = useState([])
  const [getProducts] = useGetProductsListMutation()
  const [deactivate] = useDeactivateProductMutation()
  const [activate] = useActivateProductMutation()
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  var userRole = token ? jwtDecode(token).user.role : null
 
  useEffect(()=>{
    const fetchProducts = async() =>{
      try {
        const res = await getProducts({branchId})
        setProducts(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchProducts()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }

  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/products/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
          </div>
        );
      },
    },
  ];

   const productColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "regionName", headerName: "Region Name", width: 120 },
    {
      field: "productName",
      headerName: "Product name",
      width: 150,
    },
    {
      field: "price",
      headerName: "Price(€)",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
    },
    { field: "isActive", headerName: "Active", width: 70 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Products
            { userRole === 1 || userRole === 2 || userRole === 3 ?
             <Link to="/products/new" className="link">
              Add New
            </Link> : <div></div>
            }
          </div>
          <DataGrid
            className="datagrid"
            rows={products}
            columns={  (userRole === 1 || userRole === 2 || userRole === 3) ?
              productColumns.concat(actionColumn) : productColumns}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Products;
