
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import NotFound from "./pages/NotFound";
import Users from "./pages/users/Users";
import Categories from "./pages/categories/Categories";
import Subcategories from "./pages/subcategories/Subcategories";
import Products from "./pages/products/Products";
import Variants from "./pages/variants/Variants";
import Profile from "./pages/profile/Profile";
import VariantGroups from "./pages/variantgroups/VariantGroups";
import Orders from "./pages/orders/Orders";
import Customers from "./pages/customers/Customers";
import CustomersCards from "./pages/customerscards/CustomersCards";
import Authorities from "./pages/authorities/Authorities";
import UserAuthorities from "./pages/userauthorities/UserAuthorities";
import Branches from "./pages/branches/Branches";
import BranchesCards from "./pages/branchescards/BranchesCards";
import PostCodes from "./pages/postcodes/PostCodes";
import Roles from "./pages/roles/Roles";
import NewUser from "./pages/users/NewUser";
import NewCategory from "./pages/categories/NewCategory";
import NewSubcategory from "./pages/subcategories/NewSubcategory";
import NewProduct from "./pages/products/NewProduct";
import NewVariant from "./pages/variants/NewVariant";
import NewVariantGroup from "./pages/variantgroups/NewVariantGroup";
import SingleOrder from "./pages/orders/SingleOrder";
import NewPostCodes from "./pages/postcodes/NewPostCodes";
import NewBranches from "./pages/branches/NewBranches";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./redux/authReducer";
import jwtDecode from "jwt-decode";
import NewBranchesCards from "./pages/branchescards/NewBranchesCards";
function App() {
  const token = useSelector(selectCurrentToken)
  var user = token ? jwtDecode(token) : null  
  var userRole = token ? jwtDecode(token).user.role : null  
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={ user ? <Home /> : <Login />} />
            <Route path="users">
              <Route index element={user  && (userRole === 1 || userRole === 2 || userRole === 3) ? <Users /> : user && userRole === 4 ?<Home /> : <Login />}/>
              <Route path="new" element={<NewUser/>}/>
            </Route>
            <Route path="categories">
              <Route index element={user ?  <Categories />  : <Login />} />
              <Route path="new" element={<NewCategory/>}/>
            </Route> 
            <Route path="subcategories">
              <Route index element={user ? <Subcategories /> : <Login />} />
              <Route path="new" element={<NewSubcategory/>}/>
            </Route>
            <Route path="products">
              <Route index element={ user ? <Products /> : <Login />} />
              <Route path="new" element={<NewProduct/>}/>
              {/* <Route path=":id" element={user ? <SingleProduct/> : <Login/>}/> */}
            </Route> 
            <Route path="variants">
              <Route index element={user && (userRole === 1 || userRole === 2 || userRole === 3 ) ? <Variants />: user && userRole === 4 ?<Home />  : <Login />} />
              <Route path="new" element={<NewVariant/>}/>
            </Route> 
            <Route path="variantgroups">
              <Route index element={user && (userRole === 1 || userRole === 2 || userRole === 3 )? <VariantGroups /> :user && userRole === 4 ? <Home />  : <Login />} />
              <Route path="new" element={<NewVariantGroup/>}/>
            </Route>
            <Route path="orders">
              <Route index element={user ? <Orders /> : <Login />} />
              <Route path=":id" element={user ? <SingleOrder/> : <Login/>}/>
            </Route>
            <Route path="customers" element={user && (userRole === 1 || userRole === 2 || userRole === 3 ) ? <Customers /> :user && userRole === 4 ? <Home /> : <Login />} />
            <Route path="customerscards" element={user && (userRole === 1 || userRole === 2) ? <CustomersCards /> :user && (userRole === 3  || userRole === 4 )? <Home /> :  <Login />} />
            <Route path="authorities" element={user && (userRole === 1 ) ? <Authorities /> : user && (userRole === 2  || userRole === 3  || userRole === 4 )? <Home /> : <Login />} />
            <Route path="userauthorities" element={user && (userRole === 1 ) ? <UserAuthorities /> : user && (userRole === 2  || userRole === 3  || userRole === 4 )? <Home /> :<Login />} />
            <Route path="branches">
              <Route index element={user  && (userRole === 1 || userRole === 2 )  ? <Branches /> :  user && (userRole === 3  || userRole === 4 )? <Home /> :<Login />} />
              <Route path="new" element={user && (userRole === 1 || userRole === 2 ) ? <NewBranches/> : user && (userRole === 3  || userRole === 4 )? <Home /> : <Login />}/>
            </Route>
            <Route path="branchescards">
              <Route index element={user  && (userRole === 1 || userRole === 2 ) ? <BranchesCards/> : user && (userRole === 3  || userRole === 4 )? <Home /> : <Login />} />
              <Route path="new" element={<NewBranchesCards/>}/>
            </Route> 
            <Route path="postcodes">
              <Route index element={user && (userRole === 1 || userRole === 2) ? <PostCodes /> : user && ( userRole === 3  || userRole === 4 )? <Home /> : <Login />} />
              <Route path="new" element={user && (userRole === 1 || userRole === 2 ) ? <NewPostCodes/> : user && (userRole === 3  || userRole === 4 )? <Home /> : <Login />}/>
            </Route>
            <Route path="roles" element={user && (userRole === 1 ) ? <Roles /> : user && (userRole === 2  || userRole === 3  || userRole === 4 )? <Home /> : <Login />} />
            <Route path="profile" element={user ? <Profile /> : <Login />} />
          </Route>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>    
  );
}

export default App;
