import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useAddProductMutation } from "../../api/productApiSlice";
import { useEffect } from "react";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetVariantsMutation } from "../../api/variantApiSlice";
import { useGetCategoriesMutation } from "../../api/categoryApiSlice";
import { useGetSubcategoriesMutation } from "../../api/subcategoryApiSlice";
const NewProduct = () => {
  const notify = (error) => toast(error);
  const [file, setFile] = useState("");
  const [product,setProduct] = useState()
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  //variant-------------------------------------------------------------------
  const [getVariants] = useGetVariantsMutation()
  const [variants,setVariants] = useState([])
  const variantsList = []
  useEffect(()=>{
    const fetchVariants = async()=>{
      const res = await getVariants({branchId})
      setVariants(res.data)
    }
    fetchVariants()
  },[])
  
  variants.map(variant=>variantsList.push({value:variant.id,label:variant.variantName}))
  const [variantsOption,setVariantsOption] = useState()
  const handleVariants = (data) =>{
    setVariantsOption(data)
  }
  //----------------------------------------------------------------------
    //categories-------------------------------------------------------------------
    const [getCategories] = useGetCategoriesMutation()
    const [categories,setCategories] = useState([])
    const categoriesList = []
    useEffect(()=>{
      const fetchCategories = async()=>{
        const res = await getCategories({branchId})
        setCategories(res.data)
      }
      fetchCategories()
    },[])
    
    categories.map(category=>categoriesList.push({value:category.id,label:category.categoryName}))
    const [categoriesOption,setCategoriesOption] = useState()
    const handleCategories = (data) =>{
      setCategoriesOption(data)
    }
    //----------------------------------------------------------------------
      //subcategories-------------------------------------------------------------------
  const [getSubcategories] = useGetSubcategoriesMutation()
  const [subcategories,setSubcategories] = useState([])
  const subcategoriesList = []
  useEffect(()=>{
    const fetchSubcategories = async()=>{
      const res = await getSubcategories({branchId})
      setSubcategories(res.data)
    }
    fetchSubcategories()
  },[])
  
  subcategories.map(subcategory=>subcategoriesList.push({value:subcategory.id,label:subcategory.subcategoryName}))
  const [subcategoriesOption,setSubcategoriesOption] = useState()
  const handleSubcategories = (data) =>{
    setSubcategoriesOption(data)
  }
  
  //----------------------------------------------------------------------
        // isDeposit ----------------------------------------
const isDepositList = [{value:1,label:"Ja"},{value:0,label:"Nein"}]
const [isDeposit,setIsDeposit] = useState()
const handleIsDeposit = (data) =>{
  setIsDeposit(data)
}
//--------------------------------------
        // Piece Or Kilo ----------------------------------------
const pieceOrKiloList = [{value:1,label:"Piece"},{value:0,label:"Kilo"}]
const [pieceOrKilo,setPieceOrKilo] = useState()
const handlePieceOrKilo = (data) =>{
  setPieceOrKilo(data)
}
//--------------------------------------
  //branches-------------------------------------------------------------------
  const [getBranches] = useGetBranchesMutation()
  const [branches,setBranches] = useState([])
  const branchesList = []
  useEffect(()=>{
    const fetchBranches = async()=>{
      const res = await getBranches()
      setBranches(res.data)
    }
    fetchBranches()
  },[])
  
  branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
  const [branchesOption,setBranchesOption] = useState()
  const handleBranches = (data) =>{
    setBranchesOption(data)
  }
  //----------------------------------------------------------------------
  const handleChange = (e) => {
    setProduct((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  const [addProduct] = useAddProductMutation()
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('productId',product.productId);
      formData.append('productName',product.productName);
      formData.append('variantId',product.variantId);
      formData.append('description',product.description);
      formData.append('taxPercent',product.taxPercent);
      formData.append('price',product.price);
      formData.append('discount',product.discount);
      formData.append('deposit',isDeposit.value);
      formData.append('categoryId',categoriesOption.value);
      formData.append('subcategoryId',subcategoriesOption.value);
      formData.append('pieceOrKilo',pieceOrKilo.label);
      formData.append('branchId',branchesOption.value !== undefined ? branchesOption.value : branchId);

          const res = await addProduct(formData)
          if (res.data.status === 200) {
            navigate("/products");
          }else{
            notify('Error!');
          }
        } catch (error) {
          notify('Error!');
        }
  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Category</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
      <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
            <form>
            <div className="formInput">
            <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
              </label>
                <input
                  type="file"
                  id="file"
                  name="image"
                  onChange={(e) => setFile(e.target.files[0])}
          
                />
              </div>
              <div className="formInput">
                  <label>Product ID</label>
                  <input name="productId" onChange={handleChange} type="number" placeholder="Type Product ID" />
              </div>
              <div className="formInput">
                  <label>Product Name</label>
                  <input name="productName" onChange={handleChange} type="text" placeholder="Type Product Name" />
              </div>
              <div className="formInput">
                  
                  <label>Variant Name(*)</label>
                <Select
                  options={variantsList}
                  placeholder="Select deposit"
                  value={variantsOption}
                  onChange={handleVariants}
                  isSearchable={true}
                  isMulti
                /></div>
              <div className="formInput">
                  <label>Description</label>
                  <input name="description" onChange={handleChange} type="text" placeholder="Type Description" />
              </div>
              <div className="formInput">
                  <label>Tax Percent</label>
                  <input name="taxPercent" onChange={handleChange} type="text" placeholder="(%)" />
              </div>
              <div className="formInput">
                  <label>Price</label>
                  <input name="price" onChange={handleChange} type="text" placeholder="Type Price (12.5)" />
              </div>
              <div className="formInput">
                  <label>Discount</label>
                  <input name="discount" onChange={handleChange} type="text" placeholder="(%)" />
              </div>
              <div className="formInput">
                  
                  <label>Deposit(*)</label>
                <Select
                  options={isDepositList}
                  placeholder="Select deposit"
                  value={isDeposit}
                  onChange={handleIsDeposit}
                  isSearchable={true}
                /></div>
                 <div className="formInput">
                  
                  <label>Category Name(*)</label>
                <Select
                  options={categoriesList}
                  placeholder="Select category"
                  value={categoriesOption}
                  onChange={handleCategories}
                  isSearchable={true}
                  
                /></div>
                 <div className="formInput">
                  
                  <label>Subcategory Name(*)</label>
                <Select
                  options={subcategoriesList}
                  placeholder="Select subcategory"
                  value={subcategoriesOption}
                  onChange={handleSubcategories}
                  isSearchable={true}
                  
                /></div>
          
              <div className="formInput">
                  
                  <label>Piece or Kilo(*)</label>
                <Select
                  options={pieceOrKiloList}
                  placeholder="Piece or Kilo"
                  value={pieceOrKilo}
                  onChange={handlePieceOrKilo}
                  isSearchable={true}
                /></div>
            {
              branchId === 0 | branchId === 1 &&
              <div className="formInput">
                <label>Branch Name</label>
              <Select
                options={branchesList}
                placeholder="Select a branch"
                value={branchesOption}
                onChange={handleBranches}
                isSearchable={true}
                
              /></div>
            }
              
            </form>
            <button onClick={handleClick}>Add</button>
            <ToastContainer />
      </div>
      </div>
    </div>
  </div>


  );
};

export default NewProduct;
