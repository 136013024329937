import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import { useGetCustomerCardsMutation } from "../../api/customercardApiSlice";
const CustomersCards = () => {
  const [customersCards,setCustomersCards] = useState([])
  const [getCustomerCards] = useGetCustomerCardsMutation()
  useEffect(()=>{
    const fetchCustomersCards = async() =>{
      try {
        const res = await getCustomerCards()
        setCustomersCards(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCustomersCards()
  },[])

  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
      
          </div>
        );
      },
    },
  ];

   const customersCardsColumns = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "name",
      headerName: "Customer Name",
      width: 150,
    },
    {
        field: "cardNo",
        headerName: "Card NO",
        width: 200,
      },
  
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Customer Card
            {/* <Link to="/users/new" className="link">
              Add New
            </Link> */}
          </div>
          <DataGrid
            className="datagrid"
            rows={customersCards}
            columns={customersCardsColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default CustomersCards;
