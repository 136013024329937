import { apiSlice } from "./apiSlice"
export const productApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.mutation({
            query: ({branchId}) => ({
                url: `/products/branches/${branchId}`,
                method: 'GET',
            }),
        }),
        getProductsList: builder.mutation({
            query: ({branchId}) => ({
                url: `/products/branches/list/${branchId}`,
                method: 'GET',
            }),
        }),
        getSingleProduct: builder.mutation({
            query: ({id}) => ({
                url: `/products/${id}`,
                method: 'GET',
            }),
        }),
        addProduct: builder.mutation({
            query: formData => ({
                url: '/products',
                method: 'POST',
                body:formData,
               
            }),
        }),
        deactivateProduct: builder.mutation({
            query: ({id}) => ({
                url: `/products/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateProduct: builder.mutation({
            query: ({id}) => ({
                url: `/products/activate/${id}`,
                method: 'PUT',
            }),
        }),
     
})
})
export const {
useAddProductMutation,
useActivateProductMutation,
useDeactivateProductMutation,
useGetProductsMutation,
useGetProductsListMutation,
useGetSingleProductMutation
} = productApiSlice 