import { apiSlice } from "./apiSlice"
export const postcodeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPostCodes: builder.mutation({
            query: ({branchId}) => ({
                url: `/postcodes/${branchId}`,
                method: 'GET',
            }),
        }),
        addPostCode: builder.mutation({
            query: formData => ({
                url: '/postcode',
                method: 'POST',
                body:formData
            }),
        }),
        deactivatePostCode: builder.mutation({
            query: ({id}) => ({
                url: `/postcodes/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activatePostCode: builder.mutation({
            query: ({id}) => ({
                url: `/postcodes/activate/${id}`,
                method: 'PUT',
            }),
        }),
        deletePostCode : builder.mutation({
            query: ({id}) => ({
                url: `/postcodes/${id}`,
                method: 'DELETE',
            })
        }),
     
})
})
export const {
useActivatePostCodeMutation,
useDeactivatePostCodeMutation,
useDeletePostCodeMutation,
useAddPostCodeMutation,
useGetPostCodesMutation
} = postcodeApiSlice 