import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import {Link} from "react-router-dom"
import Sidebar from "../../components/sidebar/Sidebar";
import { selectCurrentToken } from "../../redux/authReducer";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import {  useGetWaitingOrdersMutation } from "../../api/orderApiSlice";
const Orders = () => {
  const [orders,setOrders] = useState([]) 
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  const [getWaitingOrders] = useGetWaitingOrdersMutation()
  useEffect(()=>{
    const fetchOrders = async() =>{
      try {
        const res = await getWaitingOrders({branchId})
        setOrders(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOrders()
  },[])


  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/orders/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>

            
          </div>
        );
      },
    },
  ];

   const ordersColumns = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "customerId",
      headerName: "Customer ID",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
    },

      {
        field:  "note",
        headerName: "Note",
        width: 200,
      },

    { field: "isCompleted", headerName: "Completed", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Order
            {/* <Link to="/users/new" className="link">
              Add New
            </Link> */}
          </div>
          <DataGrid
            className="datagrid"
            rows={orders}
            columns={ordersColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Orders;
