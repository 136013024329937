import { apiSlice } from "./apiSlice"
export const orderApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrders: builder.mutation({
            query: ({branchId}) => ({
                url: `/orders/${branchId}`,
                method: 'GET',
            }),
        }),
        getSingleOrder: builder.mutation({
            query: ({id}) => ({
                url: `/orders/single/${id}`,
                method: 'GET',
            }),
        }),
        getWaitingOrders: builder.mutation({
            query: ({branchId}) => ({
                url: `/orders/waiting/${branchId}`,
                method: 'GET',
            }),
        }),
        completeOrder: builder.mutation({
            query: ({id}) => ({
                url: `/orders/complete/${id}`,
                method: 'PUT',
                
            }),
        }),
    })
})

export const {
    useGetOrdersMutation,
    useGetSingleOrderMutation,
    useGetWaitingOrdersMutation,
    useCompleteOrderMutation
} = orderApiSlice 