import React from 'react'

const Navbar = () => {
  return (
    <div class="navbar"></div>
//     <nav class="navbar navbar-expand-lg bg-danger navbar-dark">
//         {/* <div class="container-fluid">
        
//             <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" aria-label="Toggle navigation"
//             aria-controls="navbarSupportedContent" aria-expanded="false" data-mdb-target="#navbarSupportedContent" >
//                 <i class="fas fa-bars"></i>
//             </button>
    
//             <div class="collapse navbar-collapse" id="navbarSupportedContent">
//                 <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
    
//                 <ul class="navbar-nav d-flex flex-row me-1">
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="fas fa-print"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="far fa-clock"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="fas fa-image"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="fab fa-google-plus-g"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="far fa-calendar-alt"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="fas fa-align-justify"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="fas fa-download"></i></a>
//                     </li>
//                     <li class="nav-item me-3 me-lg-0">
//                         <a class="nav-link mx-1 text-white" href="#"><i class="fas fa-edit"></i></a>
//                     </li>
//                 </ul>
//             </div>
//         </div> */}
    
//     </nav>

  )
}

export default Navbar
