import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useGetAuthoritiesMutation } from "../../api/authorityApiSlice";
const Authorities = () => {
  const [authorities,setAuthorities] = useState([])
  const [getAuthorities] = useGetAuthoritiesMutation()
  useEffect(()=>{
    const fetchAuthorities = async() =>{
      try {
        const res = await getAuthorities()
        setAuthorities(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchAuthorities()
  },[])




   const authorityColumns = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "name",
      headerName: "Authority Name",
      width: 150,
    },
  
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Authority
            {/* <Link to="/users/new" className="link">
              Add New
            </Link> */}
          </div>
          <DataGrid
            className="datagrid"
            rows={authorities}
            columns={authorityColumns}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Authorities;
