import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddVariantMutation } from "../../api/variantApiSlice";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
import { useEffect } from "react";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetVariantGroupsMutation } from "../../api/variantgroupApiSlice";
const NewVariant = () => {
  const notify = (error) => toast(error);
  const [variant,setVariant] = useState()
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
      // isMultiple ----------------------------------------
const isMultiList = [{value:1,label:"Ja"},{value:0,label:"Nein"}]
const [isMulti,setIsMulti] = useState()
const handleIsMulti = (data) =>{
  setIsMulti(data)
}
//--------------------------------------

//VariantGroups---------------
const [getVariantGroups] = useGetVariantGroupsMutation()
const [variantGroups,setVariantGroups] = useState([])
const variantGroupsList = []
useEffect(()=>{
  const fetchVariantGroups = async()=>{
    try{
    const res = await getVariantGroups({branchId})
    setVariantGroups(res.data)
  } catch (error) {
    notify(error.message)
  }

  }
  fetchVariantGroups()
},[])

variantGroups.map(group=>variantGroupsList.push({value:group.id,label:group.variantGroupName}))
const [variantGroupsOption,setVariantGroupsOption] = useState()
const handleVariantGroup = (data) =>{
  setVariantGroupsOption(data)
}
//----------------------------

  //branches-------------------------------------------------------------------
  const [getBranches] = useGetBranchesMutation()
  const [branches,setBranches] = useState([])
  const branchesList = []
  useEffect(()=>{
    const fetchBranches = async()=>{
      const res = await getBranches()
      setBranches(res.data)
    }
    fetchBranches()
  },[])
  
  branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
  const [branchesOption,setBranchesOption] = useState()
  const handleBranches = (data) =>{
    setBranchesOption(data)
  }
 
  //----------------------------------------------------------------------
  const handleChange = (e) => {
    setVariant((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    const [addVariant] = useAddVariantMutation()
  const handleClick = async (e) => {
    e.preventDefault();
        try {
          await addVariant({variantId:variant.variantId,variantName:variant.variantName,multiple:isMulti.value,variantGroupId:variant.variantGroupId,price:variant.price,note:variant.note,branchId:variant.branchId,})
          navigate("/variants");
        } catch (error) {
          notify(error)
        }
      }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Variant</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
            <form>
            <div className="formInput">
                  <label>Variant ID(*)</label>
                  <input name="variantId" onChange={handleChange} type="text" placeholder="Type Variant ID" />
              </div>
              <div className="formInput">
                  <label>Variant Name(*)</label>
                  <input name="variantName" onChange={handleChange} type="text" placeholder="Type Variant Name" />
              </div>
              <div className="formInput">
                  
                  <label>Multiple(*)</label>
                <Select
                  options={isMultiList}
                  placeholder="Select multiple status"
                  value={isMulti}
                  onChange={handleIsMulti}
                  isSearchable={true}
                /></div>
             
             <div className="formInput">
                  
                  <label>Variant Group(*)</label>
                <Select
                  options={variantGroupsList}
                  placeholder="Select variant groups"
                  value={variantGroupsOption}
                  onChange={handleVariantGroup}
                  isSearchable={true}
                /></div>

              <div className="formInput">
                  <label>Price(*)</label>
                  <input name="price" onChange={handleChange} type="text" placeholder="Type Price" />
              </div>
              <div className="formInput">
                  <label>Note(*)</label>
                  <input name="note" onChange={handleChange} type="text" placeholder="Type Note" />
              </div>
              {
              branchId === 0 | branchId === 1 &&
              <div className="formInput">
                <label>Branch(*)</label>
              <Select
                required
                options={branchesList}
                placeholder="Select branch"
                value={branchesOption}
                onChange={handleBranches}
                isSearchable={true}
                
              /></div>
                }
              
            </form>
            <button onClick={handleClick}>Add</button>
            <ToastContainer />
      </div>
      </div>
    </div>
  </div>


  );
};

export default NewVariant;
