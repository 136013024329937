import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import { useActivateRoleMutation, useDeactivateRoleMutation, useGetRolesMutation } from "../../api/roleApiSlice";
const Roles = () => {
  const [roles,setRoles] = useState([])
  const [getRoles] = useGetRolesMutation()
  const [deactivate] = useDeactivateRoleMutation()
  const [activate] = useActivateRoleMutation()

  useEffect(()=>{
    const fetchRoles = async() =>{
      try {
        const res = await getRoles()
        setRoles(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchRoles()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }

  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
          </div>
        );
      },
    },
  ];

   const rolesColumns = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "roleName",
      headerName: "Role Name",
      width: 150,
    },
    { field: "isActive", headerName: "Active", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Role
            {/* <Link to="/users/new" className="link">
              Add New
            </Link> */}
          </div>
          <DataGrid
            className="datagrid"
            rows={roles}
            columns={rolesColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Roles;

 
 


