import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
import { useAddCategoryMutation } from "../../api/categoryApiSlice";

const NewCategory = () => {
  const [addCategory] = useAddCategoryMutation()
  const [error,setError] = useState(false)
  const [category,setCategory] = useState()
  const [getBranches] = useGetBranchesMutation()
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null

  //branches-------------------------------------------------------------------

const [branches,setBranches] = useState([])
const branchesList = []
useEffect(()=>{
  const fetchBranches = async()=>{
    const res = await getBranches()
    setBranches(res.data)
  }
  fetchBranches()
},[])

branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
const [branchesOption,setBranchesOption] = useState()
const handleBranches = (data) =>{
  setBranchesOption(data)
}
//----------------------------------------------------------------------

  const handleChange = (e) => {
    setCategory((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
 
  const handleClick = async (e) => {
    e.preventDefault();
        try {
          await addCategory({categoryName:category.categoryName,branchId:branchesOption !== undefined ? branchesOption.value : branchId })
          navigate("/categories");
        } catch (error) {
          setError(true)
        }
  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Category</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
            <form>
              <div className="formInput">
                  <label>Category Name</label>
                  <input name="categoryName" onChange={handleChange} type="text" placeholder="Type Category" />
              </div>
             {
              branchId === 0 &&
              <div className="formInput">
                <label>Branch Name</label>
              <Select
                options={branchesList}
                placeholder="Select a branch"
                value={branchesOption}
                onChange={handleBranches}
                isSearchable={true}
                
              /></div>
                }
            </form>
            <button onClick={handleClick}>Add</button>
              {error && "Something went wrong!"}

      </div>
      </div>
    </div>
  </div>


  );
};

export default NewCategory;
