import { apiSlice } from "./apiSlice"
export const roleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRoles: builder.mutation({
            query: () => ({
                url: '/roles',
                method: 'GET',
            }),
        }),
     
        deactivateRole: builder.mutation({
            query: ({id}) => ({
                url: `/roles/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateRole: builder.mutation({
            query: ({id}) => ({
                url: `/roles/activate/${id}`,
                method: 'PUT',
            }),
        }),
     
})
})
export const {
useActivateRoleMutation,
useDeactivateRoleMutation,
useGetRolesMutation
} = roleApiSlice 