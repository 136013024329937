import { apiSlice } from "./apiSlice"
export const variantGroupApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVariantGroups: builder.mutation({
            query: ({branchId}) => ({
                url: `/variantgroups/${branchId}`,
                method: 'GET',
            }),
        }),
        addVariantGroup: builder.mutation({
            query: formData => ({
                url: '/variantgroups',
                method: 'POST',
                body:formData
            }),
        }),
        deactivateVariantGroup: builder.mutation({
            query: ({id}) => ({
                url: `/variantgroups/deactivate/${id}`,
                method: 'PUT',
            }),
        }),
        activateVariantGroup: builder.mutation({
            query: ({id}) => ({
                url: `/variantgroups/activate/${id}`,
                method: 'PUT',
            }),
        }),
        deleteVariantGroup: builder.mutation({
            query: ({id}) => ({
                url: `/variantgroups/${id}`,
                method: 'DELETE',
            }),
        }),
     
})
})
export const {
useAddVariantGroupMutation,
useActivateVariantGroupMutation,
useDeactivateVariantGroupMutation,
useGetVariantGroupsMutation,
useDeleteVariantGroupMutation
} = variantGroupApiSlice 