import "./sidebar.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { useState } from "react";
import CategoryIcon from '@mui/icons-material/Category';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import { useSendLogoutMutation } from "../../api/authApiSlice";


const Sidebar = () => {
  const token = useSelector(selectCurrentToken)
  var userRole = token ? jwtDecode(token).user.role : null  
  const [logout] = useSendLogoutMutation()
  const handleLogout = (e) => {
    logout()
  }
 const [menu,setMenu] = useState(false)
 const mobileMenuOpenFunc = ()=>{
  setMenu(!menu)
}
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">ElbeOrder Admin</span>
        </Link>
        <button onClick={mobileMenuOpenFunc} className={`menuIcon ${menu ? "show" : "hide"}`}>
       {
        menu ?  <CloseOutlinedIcon/> : <MenuOutlinedIcon/>
       }
        </button>
      </div>
 
      <div className="center">
        <ul>
        <p className="title">HOME</p>
         
       
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Home</span>
            </li>
          </Link> 
          <p className="title">LISTS</p>
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
             :<div></div>
            }
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <li>
              <CategoryIcon  className="icon" />
              <span>Categories</span>
            </li>
          </Link>
          <Link to="/subcategories" style={{ textDecoration: "none" }}>
            <li>
              <CategoryIcon  className="icon" />
              <span>Subcategories</span>
            </li>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <PostAddIcon  className="icon" />
              <span>Products</span>
            </li>
          </Link>
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/variants" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Variants</span>
            </li>
          </Link>
            :<div></div>
          }
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/variantgroups" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Variant Groups</span>
            </li>
          </Link>
             :<div></div>
            }
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/customers" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Customers</span>
            </li>
          </Link>
            :<div></div>
          }
          { userRole === 1 || userRole === 2 ?
          <Link to="/customerscards" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Customers Cards</span>
            </li>
          </Link>
          :<div></div>
        }
           { userRole === 1 || userRole === 2 ?
          <p className="title">LISTS 2</p>
            : <div></div>}
         { userRole === 1 ?
          <Link to="/authorities" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Authorities</span>
            </li>
          </Link> :<div></div>
          }
          { userRole === 1 ?
          <Link to="/userauthorities" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>User Authorities</span>
            </li>
          </Link> :<div></div>
          }
            { userRole === 1 || userRole === 2 ?
          <Link to="/branches" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Branches</span>
            </li>
          </Link> 
          :<div></div>
        }
          { userRole === 1 || userRole === 2 ?
          <Link to="/branchescards" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Branches Cards</span>
            </li>
          </Link>
         :<div></div>
        }
           { userRole === 1 || userRole === 2 ?
          <Link to="/postcodes" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Post Codes</span>
            </li>
          </Link>
            :<div></div>
          }
           { userRole === 1 ?
          <Link to="/roles" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Roles</span>
            </li>
          </Link>
            :<div></div>
          }
          <p className="title">USER</p>
          
          <Link to="/profile" style={{ textDecoration: "none" }}>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          </Link>
          
          <li onClick= {handleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
          
        </ul>
      </div>
      <div className={`mobileCenter ${menu ? "show" : "hide"}`}>
      <ul>
        <p className="title">HOME</p>
         
       
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Home</span>
            </li>
          </Link> 
          <p className="title">LISTS</p>
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
             :<div></div>
            }
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <li>
              <CategoryIcon  className="icon" />
              <span>Categories</span>
            </li>
          </Link>
          <Link to="/subcategories" style={{ textDecoration: "none" }}>
            <li>
              <CategoryIcon  className="icon" />
              <span>Subcategories</span>
            </li>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <PostAddIcon  className="icon" />
              <span>Products</span>
            </li>
          </Link>
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/variants" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Variants</span>
            </li>
          </Link>
            :<div></div>
          }
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/variantgroups" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Variant Groups</span>
            </li>
          </Link>
             :<div></div>
            }
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>
          { userRole === 1 || userRole === 2 || userRole === 3 ?
          <Link to="/customers" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Customers</span>
            </li>
          </Link>
            :<div></div>
          }
          { userRole === 1 || userRole === 2 ?
          <Link to="/customerscards" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Customers Cards</span>
            </li>
          </Link>
          :<div></div>
        }
           { userRole === 1 || userRole === 2 ?
          <p className="title">LISTS 2</p>
            : <div></div>}
         { userRole === 1 ?
          <Link to="/authorities" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Authorities</span>
            </li>
          </Link> :<div></div>
          }
          { userRole === 1 ?
          <Link to="/userauthorities" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>User Authorities</span>
            </li>
          </Link> :<div></div>
          }
            { userRole === 1 || userRole === 2 ?
          <Link to="/branches" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Branches</span>
            </li>
          </Link> 
          :<div></div>
        }
          { userRole === 1 || userRole === 2 ?
          <Link to="/branchescards" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Branches Cards</span>
            </li>
          </Link>
         :<div></div>
        }
           { userRole === 1 || userRole === 2 ?
          <Link to="/postcodes" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Post Codes</span>
            </li>
          </Link>
            :<div></div>
          }
           { userRole === 1 ?
          <Link to="/roles" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Roles</span>
            </li>
          </Link>
            :<div></div>
          }
          <p className="title">USER</p>
          
          <Link to="/profile" style={{ textDecoration: "none" }}>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          </Link>
          
          <li onClick= {handleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
          
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
