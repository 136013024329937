import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { selectCurrentToken } from "../../redux/authReducer";
import { useSelector } from "react-redux";
import { useActivateSubcategoryMutation, useDeactivateSubcategoryMutation, useDeleteSubcategoryMutation, useGetSubcategoriesMutation } from "../../api/subcategoryApiSlice";
const Subcategories = () => {
  const [subcategories,setSubcategories] = useState([])
  const [getSubcategories] = useGetSubcategoriesMutation()
  const [deactivate] = useDeactivateSubcategoryMutation()
  const [activate] = useActivateSubcategoryMutation()
  const [deleteSubcategory] = useDeleteSubcategoryMutation()
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  var userRole = token ? jwtDecode(token).user.role : null
  useEffect(()=>{
    const fetchSubcategories = async() =>{
      try {
        const res = await getSubcategories({branchId})
        setSubcategories(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchSubcategories()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }

  
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async(id) =>{
    try {
      await deleteSubcategory({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  //search alanı olacak

 
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 270,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
            <div
              className="deleteButton"
               onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

   const subcategoryColumns = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "subcategoryName",
      headerName: "Subcategory name",
      width: 150,
    },
    { field: "name", headerName: "Branch Name", width: 120 },
    { field: "regionName", headerName: "Region Name", width: 120 },
    { field: "isActive", headerName: "Active", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Subcategories
            { userRole === 1 || userRole === 2 || userRole === 3 ?
            <Link to="/subcategories/new" className="link">
              Add New
            </Link> : <div></div>
            }
          </div>
          <DataGrid
            className="datagrid"
            rows={subcategories}
            columns={  (userRole === 1 || userRole === 2 || userRole === 3) ?
            subcategoryColumns.concat(actionColumn) : subcategoryColumns}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Subcategories;
