import React from 'react'
import "./profile.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { useSelector } from 'react-redux'
import jwtDecode from "jwt-decode"
const Profile = () => {
  const user = useSelector(state=>state.auth.user)
  const authUser = jwtDecode(user)
  console.log(authUser)

    return (
        <div className="home">
            <Sidebar/>
            <div className="homeContainer">
                <Navbar/>
                <div className="container container-fluid">
                    <h2 className="mt-5 ml-1">My Profile</h2>
                    <div className="row justify-content-start mt-5 user-info">
                        <div className="col-12 col-md-3">
                            <figure className='avatar avatar-profile'>
                                <img src="/user.png" width={"200px"} alt="user" />
                            </figure>
                        </div>
                        <div className="col-12 col-md-5">
                            <h4>UserName</h4>
                            <p>{authUser.user.username}</p>
                                          
                            <h4>Role</h4>
                            <p>{authUser.user.roleName}</p>

                            <h4>Branch</h4>
                            <p>{authUser.user.branchName ?? "No branch!"}</p>
        {/* <div
          className="resetButton"
           onClick={() => handleResetUserPass(authUser.userId)}
        >
          Reset Password
        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
