import { apiSlice } from "./apiSlice"
export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.mutation({
            query: ({branchId}) => ({
                url: `/users/${branchId}`,
                method: 'GET',
            }),
        }),
        addUser:builder.mutation({
            query: formData => ({
                url: '/users',
                method: 'POST',
                body: formData
            }),
        }),
        deactivateUser : builder.mutation({
            query: ({id}) => ({
                url: `/users/deactivate/${id}`,
                method: 'PUT',
            })
        }),
        activateUser : builder.mutation({
            query: ({id}) => ({
                url: `/users/activate/${id}`,
                method: 'PUT',
            })
        }),
        deleteUser : builder.mutation({
            query: ({id}) => ({
                url: `/users/${id}`,
                method: 'DELETE',
            })
        })
})
})
export const {
    useGetUsersMutation,
    useDeactivateUserMutation,
    useAddUserMutation,
    useActivateUserMutation,
    useDeleteUserMutation
} = userApiSlice 