import "../../style/commonAddData.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { selectCurrentToken } from "../../redux/authReducer";
import { useAddPostCodeMutation } from "../../api/postcodeApiSlice";
import { useGetBranchesMutation } from "../../api/branchApiSlice";
const NewPostCodes = () => {
  //toastify
  const notify = (error) => toast(error);
  const [postCodes,setPostCodes] = useState()
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null

  //branches-------------------------------------------------------------------
const [getBranches] = useGetBranchesMutation()
const [branches,setBranches] = useState([])
const branchesList = []
useEffect(()=>{
  const fetchBranches = async()=>{
    const res = await getBranches()
    setBranches(res.data)
  }
  fetchBranches()
},[])

branches.map(branch=>branchesList.push({value:branch.id,label:branch.name + "," +  branch.regionName}))
const [primaryBranchesOption,setPrimaryBranchesOption] = useState()
const [secondaryBranchesOption,setSecondaryBranchesOption] = useState()
const handleBranchesPrimary = (data) =>{
  setPrimaryBranchesOption(data)
}
const handleBranchesSecondary = (data) =>{
  setSecondaryBranchesOption(data)
}
//----------------------------------------------------------------------

  const handleChange = (e) => {
    setPostCodes((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
const [addPostCode] = useAddPostCodeMutation()
  const handleClick = async (e) => {
    e.preventDefault();

        try {
        await addPostCode({postCode:postCodes.postCode,primaryBranchId:primaryBranchesOption !== undefined ? primaryBranchesOption.value : branchId,secondaryBranchId:secondaryBranchesOption !== undefined ? secondaryBranchesOption.value : branchId,minOrder:postCodes.minOrder,deliveryFee:postCodes.deliveryFee})
        navigate("/postcodes");
        } catch (error) {
          notify(error.message)
        }

  }

  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar/> */}
      <div className="addNewTop">
          <h5>Add New Post Code</h5>
      </div>
      <div className="addNewBottom">
      <div className="right">
            <form onSubmit={handleClick}>
              <div className="formInput">
                  <label>Post Code(*)</label>
                  <input name="postCode" required onChange={handleChange} type="number" placeholder="Type Post Code" />
              </div>
             {
              branchId === 0 | branchId === 1 &&
              <div className="formInput">
                <label>Primary Branch(*)</label>
              <Select
                required
                options={branchesList}
                placeholder="Select primary branch"
                value={primaryBranchesOption}
                onChange={handleBranchesPrimary}
                isSearchable={true}
                
              /></div>
                }
                 {
              branchId === 0 | branchId === 1 &&
              <div className="formInput">
                <label>Secondary Branch(*)</label>
              <Select
                required
                options={branchesList}
                placeholder="Select secondary branch"
                value={secondaryBranchesOption}
                onChange={handleBranchesSecondary}
                isSearchable={true}
                
              /></div>
                }
              <div className="formInput">
                  <label>Minimum Order Amount(*)</label>
                  <input name="minOrder" required onChange={handleChange} type="number" placeholder="Type Minimum Amount" />
              </div>
              <div className="formInput">
                  <label>Delivery Fee(*)</label>
                  <input name="deliveryFee" required onChange={handleChange} type="number" placeholder="Type Delivery Fee" />
              </div>
              <button>Add</button>
            </form>
          
              
      <ToastContainer />

      </div>
      </div>
    </div>
  </div>


  );
};

export default NewPostCodes;
