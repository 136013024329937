import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/authReducer";
import jwtDecode from "jwt-decode";
import { useActivateVariantMutation, useDeactivateVariantMutation, useDeleteVariantMutation, useGetVariantsMutation } from "../../api/variantApiSlice";

const Variants = () => {
  const [variants,setVariants] = useState([])
  const [getVariants] = useGetVariantsMutation()
  const [deactivate] = useDeactivateVariantMutation()
  const [activate] = useActivateVariantMutation()
  const [deleteVariant] = useDeleteVariantMutation()
  const token = useSelector(selectCurrentToken)
  var branchId = token ? jwtDecode(token).user.branchId : null
  useEffect(()=>{
    const fetchVariants = async() =>{
      try {
        const res = await getVariants({branchId})
        setVariants(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchVariants()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  const handleActivate = async(id) =>{
    try {
      await activate({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async(id) =>{
    try {
      await deleteVariant({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 270,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
            <div
              className="deleteButton"
               onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

   const variantColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "variantId", headerName: "Variant ID", width: 120 },
    {
      field: "regionName",
      headerName:"Region Name",
      width:100
    },
    {
      field: "variantName",
      headerName: "Variant Name",
      width: 120,
    },
      {
        field: "variantGroupName",
        headerName: "Variant Name",
        width: 120,
      },
  
      {
        field: "multiple",
        headerName: "Multiple",
        width: 70,
      },
      {
        field: "variantPrice",
        headerName: "Price",
        width: 100,
      },
      {
        field: "note",
        headerName: "Note",
        width: 150,
      },

    { field: "isActive", headerName: "Active", width: 70 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Variant
            <Link to="/variants/new" className="link">
              Add New
            </Link>
          </div>
          <DataGrid
            className="datagrid"
            rows={variants}
            columns={variantColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default Variants;
