import "../../style/common.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";

import { useActivateBranchescardMutation, useDeactivateBranchescardMutation, useDeleteBranchescardMutation, useGetBranchescardsMutation } from "../../api/branchescardApiSlice";
const BranchesCards = () => {
  const [branchesCards,setBranchesCards] = useState([])
  const [getBranchescards] = useGetBranchescardsMutation()
  const [deactivateBranchescard] = useDeactivateBranchescardMutation()
  const [activateBranchescard] = useActivateBranchescardMutation()
  const [deleteBranchescard] = useDeleteBranchescardMutation()


  useEffect(()=>{
    const fetchBranchesCards = async() =>{
      try {
        const res = await getBranchescards()
        setBranchesCards(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchBranchesCards()
  },[])

  const handleDeactivate = async(id) =>{
    try {
      await deactivateBranchescard({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  const handleActivate = async(id) =>{
    try {
      await activateBranchescard({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async(id) =>{
    try {
      await deleteBranchescard({id})
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 270,
      renderCell: (params) => {
        return (
          <div className="cellAction">      
            <div
              className="deleteButton"
               onClick={() => handleDeactivate(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="viewButton"
               onClick={() => handleActivate(params.row.id)}
            >
              Activate
            </div>
            <div
              className="deleteButton"
               onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

   const branchesCardsColumns = [
    { field: "id", headerName: "ID", width: 120 },
    {
      field: "regionName",
      headerName: "Region Name",
      width: 150,
    },
    {
      field: "cardNo",
      headerName: "Card No",
      width: 200,
    },

    { field: "isActive", headerName: "Active", width: 120 },
  ]
  
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Add New Branch Card
            <Link to="/branchescards/new" className="link">
              Add New
            </Link>
          </div>
          <DataGrid
            className="datagrid"
            rows={branchesCards}
            columns={branchesCardsColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
    </div>
  </div>


  );
};

export default BranchesCards;

 
 
